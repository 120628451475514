<template>
    <a class="c-splash-page link" role="link" aria-label="Weiter" href="#" @click.prevent="goToNextPage" @touchend.prevent="goToNextPage">
        <slot></slot>
    </a>
</template>

<script>
import pageMixin from '../../mixins/page'
import throttle from '../../util/throttle'
import EventHub from '../../util/EventHub'

export default {
  mixins: [pageMixin],
  data () {
    return {
      shouldIgnoreClick: false
    }
  },
  created () {
    this.$xp.screen.preventSleep()
    console.log('XpSplash screen page', this.pageData)
    this.goToNextPage = throttle(this.goToNextPage)
    EventHub.Bus.$on(EventHub.TYPES.CLICKED_HOTSPOT_SPLASHSCREEN, () => {
      this.shouldIgnoreClick = true
    })
  },
  mounted () {
    this.$xp.history.addHomePage(this.$route.params.id, this.$route.path, this.pageData.Title, 0, [])
    this.$el.focus()
  },
  methods: {
    goToNextPage () {
      this.$xp.tracker && this.$xp.tracker.installListener()
      if (this.shouldIgnoreClick) {
        this.shouldIgnoreClick = !this.shouldIgnoreClick
        return
      }
      console.log('goToNextPage()')
      if (this.pageData.NextPageID) {
        this.$router.push('/page/' + this.pageData.NextPageID)
      } else {
        console.warn('Splash screen has no next page')
      }
    }
  }
}
</script>
